import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import { ArrowOutward } from '@mui/icons-material'
import { useTranslation } from 'next-i18next'
import GetStartedButton from '@imports/GetStartedButton'
import SetupMascot from '@assets/mascots/SetupMascot'
import SetupSMMascot from '@assets/mascots/SetupSMMascot'

function SetupSection() {
  const { t, i18n } = useTranslation(['common', 'setup'])
  const scaleX = i18n.dir(i18n.language) === 'rtl' ? -1 : 1
  return (
    <Box sx={{ backgroundColor: 'white', position: 'relative', zIndex: 2 }}>
      <Box
        py={10}
        sx={{ display: 'flex', alignItems: 'center', position: 'relative', zIndex: 2 }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            spacing={6}
          >
            <Grid
              item
              xs={12}
            >
              <Typography
                variant="h5"
                fontWeight={500}
              >
                {t('setup:is_Blue_Assembly_the_communication_tool')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography
                variant="h3"
                fontWeight={300}
              >
                {t('setup:Set_it_up_for_your_school')}
                <Typography
                  variant="h3"
                  fontWeight={700}
                  component="span"
                >
                  {' '}
                  {t('setup:in_less_than_10_minutes')}
                </Typography>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <GetStartedButton
                color="primary"
                endIcon={<ArrowOutward />}
                label={t('common:get_started_free')}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
          transform: `scaleX(${scaleX})`,
          position: 'absolute',
          right: 0,
          top: -90,
          zIndex: 0,
          opacity: 0.7,
        }}
      >
        <SetupMascot />
      </Box>
      <Box
        sx={{
          display: { xs: 'block', md: 'none' },
          transform: `scaleX(${scaleX})`,
          position: 'absolute',
          right: 0,
          bottom: -275,
          opacity: 0.8,
        }}
      >
        <SetupSMMascot />
      </Box>
    </Box>
  )
}

export default SetupSection
